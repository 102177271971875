
import _ from 'underscore';

export function trimToNull(str) {
    const trimmed = trimToEmpty(str);
    return _.isEmpty(trimmed) ? null : trimmed;
}

export function trimToEmpty(str) {
    return (str || '').toString().trim();
}